<template>
  <div style="display: inline-block" v-show="!isHidden">
    <el-tooltip v-if="tooltip" effect="dark" :content="tooltip" placement="right">
      <el-button :type="type" :icon="action.icon" :plain="plain" :style="CSS" :round="round" @click="onClick" :disabled="readonly">
        <template v-if="text">
          {{text}}
        </template>
      </el-button>
    </el-tooltip>
    <el-button v-else :type="type" :icon="action.icon" :plain="plain" :style="CSS" :round="round" @click="onClick" :disabled="readonly">
      <template v-if="text">
        {{text}}
      </template>
    </el-button>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import Dashboard from '@/components/Dashboard'
import RegistryCard from '@/components/RegistryCard'
import PluginFactory from '../../../../core/infrastructure/service/PluginFactory'

import Registry from '@/components/Registry/Models/Registry'

export default {
  name: 'a-btn',
  components: {
    Dashboard,
    RegistryCard
  },
  mixins: [mixin, VisibleMixin],
  inject: {
    getParentContext: {
      default: () => {}
    },
    getModel: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    }
  },
  props: {
    type: {
      type: String,
      description: 'Тип'
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    plain: {
      type: Boolean,
      description: 'Простая'
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    text: {
      type: String,
      description: 'Текст',
      default: 'Текст'
    },
    round: {
      type: Boolean,
      description: 'Круглая'
    },
    pluginName: {
      type: String,
      editor: 'Plugin',
      description: 'Плагин'
    },
    action: {
      type: Object,
      editor: 'ButtonAction',
      default: () => {
        return {
          type: 'execute_plugin',
          dashboard: {
            id: null,
            isFullscreen: false,
            window_width: 25,
            window_title: null
          },
          command: {
            id: null,
            success_text: null,
            failure_text: null
          },
          card: {
            registryId: null,
            type: null,
            fieldId: null,
            constantRecordId: null,
            cardId: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: []
          },
          reports: {
            id: null,
            guid: null,
            name: null,
            formatType: null,
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          }
        }
      }
    }
  },
  computed: {
    dataFilters () {
      let filters = []
      if (this.action.filters) {
        this.action.filters.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              filters.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push({
              key: item.alias,
              value: item.attribute
            })
          }
        })
      }
      return filters
    }
  },
  methods: {
    getDefaultsForCard () {
      let defaults = []
      if (this.action.card.defaults) {
        this.action.card.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    },
    async getCardId (registryId, recordId = null) {
      console.log('id card not set')
      let url = `${this.$config.api}/registryservice/registry/${registryId}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)

      return data.data[0]
    },
    async getFastCard (recordData = null) {
      let data = await this.$http.post(`${this.$config.api}/registryservice/registry/${this.action.card.registryId}/card`,
        recordData, { hideNotification: true })
      return data.data[0]
    },
    async onClick () {
      if (this.action.type === 'execute_plugin') {
        if (!this.pluginName) {
          console.log(`Plugin doesn't set`)
          return false
        }
        let plugin = await PluginFactory.build(this.$config.project, this.pluginName, this)
        plugin.execute()
      } else if (this.action.type === 'open_dashboard' && this.action.dashboard.id) {
        if (this.action.dashboard.isFullscreen) {
          this.openDashboardCard(this.action.dashboard.id, this.action.dashboard.window_title, null, JSON.parse(JSON.stringify(this.getModel())))
        } else {
          const h = this.$createElement
          let customClass = 'custom_scrollbar '
          if (this.action.dashboard.window_width) {
            customClass += `dashboard_window_width_${this.action.dashboard.window_width}`
          }
          this.$msgbox({
            title: this.action.dashboard.window_title,
            customClass: customClass,
            message: h('dashboard', { props: { id: this.action.dashboard.id, parentContext: this, model: JSON.parse(JSON.stringify(this.getModel())) }, key: this.generateGuid() }),
            showCancelButton: false,
            showConfirmButton: false,
            closeOnClickModal: false
          })
        }
      } else if (this.action.type === 'open_report' && this.action.reports.id) {
        // console.log(this.dataFilters)
        // this.action.reports.id
        this.addMainTab({ name: this.action.reports.name,
          componentType: 'StimulsoftViewer',
          payload: {
            filename: `${this.action.reports.guid}.mrt`,
            variables: this.dataFilters
          } })
      } else if ((this.action.type === 'open_document' || this.action.type === 'open_xml') && this.action.reports.id) {
        this.$http
          .request({
            method: 'post',
            url: `${this.$config.api}/reporteditor/reports/${this.action.reports.id}/document/${this.action.reports.formatType}`,
            data: {
              registry_id: this.action.reports.registryId,
              field_id: this.action.reports.assocFieldId,
              record_id: this.getModel()['id'],
              record_guid: this.getModel()['guid'],
              is_open_saved_version: this.action.reports.openSavedVersion,
              filters: this.dataFilters
            },
            responseType: 'json'
          })
          .then(response => {
            if (this.action.reports.viewType === 'download') {
              this.$http
                .request({
                  method: 'post',
                  url: `${this.$config.api}/reporteditor/reports/document/download`,
                  data: response.data,
                  responseType: 'blob'
                })
                .then(res => {
                  let formats = {
                    docx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    pdf: 'application/pdf',
                    xml: 'application/xml'
                  }
                  const url = window.URL.createObjectURL(new Blob([res.data], { type: formats[this.action.reports.formatType] }))
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', `${this.action.reports.name}.${this.action.reports.formatType}`)
                  document.body.appendChild(link)
                  link.click()
                  link.remove()
                })
            } else {
              if (this.action.reports.formatType !== 'xml') {
                this.addMainTab({ name: this.action.reports.name,
                  componentType: 'DocumentViewer',
                  payload: {
                    guid: this.generateGuid(),
                    filename: `${response.data.name}.${response.data.extension}`,
                    isRegistryFile: response.data.isRegistryFile,
                    reportId: this.action.reports.id
                  } })
              } else {
                console.log('XmlViewer')
                this.addMainTab({ name: this.action.reports.name,
                  componentType: 'XmlViewer',
                  payload: {
                    guid: this.generateGuid(),
                    content: response.data.content
                  } })
              }
            }

            console.log('Build and opening document successful is completed!')
          })
      } else if (this.action.type === 'execute_command' && this.action.command.id) {
        this.getCard().saveRecord({ commandId: this.action.command.id }).then((response) => {
          this.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: this.action.command.success_text,
            type: 'success'
          })
        }).catch((error) => {
          console.error(error)
          this.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: this.action.command.failure_text,
            type: 'error'
          })
        })
      } else if (this.action.type === 'open_card') {
        if (!this.action.card.registryId || !this.action.card.type) {
          console.warn('wrong parameters', this.action.card)
          return false
        }
        const readOnly = this.action.card.type === 'read'
        let recordId = null
        let card = {}
        let initialData = {}
        this.action.card.cardId
          ? card = { id: this.action.card.cardId }
          : card = await this.getCardId(this.action.card.registryId, recordId)

        let defaults = this.getDefaultsForCard()
        defaults.forEach((item) => {
          initialData[item.key] = item.value
        })
        if (this.action.card.type === 'update' || this.action.card.type === 'read') {
          console.log(`type: ${this.action.card.type}`)
          if (this.action.card.constantRecordId) {
            recordId = this.action.card.constantRecordId
          } else if (this.action.card.fieldId) {
            recordId = this.getModel()[this.action.card.fieldId]
          }
          if (!recordId) {
            console.warn(`recordId doesn't set = ${this.action.card.fieldId}, constant = ${this.action.card.constantRecordId}`)
            return false
          }
          if (!this.action.card.cardId) {
            card = await this.getCardId(this.action.card.registryId, recordId)
          }
        } else if (this.action.card.type === 'add') {
          console.log('add card', this.action.card)
          console.log('card', card)
          let isQuickAddCard = await this.isQuickAddCard()
          if (this.action.card.fieldId) {
            if (!this.getModel()['id']) {
              console.warn(`record has not id`)
              return false
            }
            let fieldId = this.action.card.fieldId
            let outerXrefComponent = Object.values(this.getDashboardComponents()).find(function (item) {
              return item[0].name === fieldId
            })
            if (outerXrefComponent && outerXrefComponent[0].outerXrefId) {
              initialData[`attr_${outerXrefComponent[0].outerXrefId}_`] = this.getModel()['id']
            }
            if (isQuickAddCard && !this.action.card.cardId) {
              let quickAddCard = isQuickAddCard
              console.log('quickAddCard', quickAddCard)
              this.openQuickAddCard(quickAddCard, initialData)

              return
            }

            let typeField = this.getCard().structure.components.filter(cmp => {
              if (cmp.properties.name && cmp.properties.name.includes(`${fieldId}`)) return true
            })[0].initialType

            if (typeField && (typeField === 'registry/xref_field' || typeField === 'registry/xref_multi_field')) {
              let me = this
              this.openRegistryCard({
                registryId: this.action.card.registryId,
                cardId: card.id,
                cardName: '',
                recordId: recordId,
                initialData: initialData,
                registry: {
                  readonly: readOnly,
                  addRecord: (recordid) => {
                    me.getModel()[me.action.card.fieldId] = recordid
                  },
                  updateRecord: () => {}
                }
              })
              return
            }
          } else {
            if (isQuickAddCard && !this.action.card.cardId) {
              let quickAddCard = isQuickAddCard
              console.log('quickAddCard', quickAddCard)
              this.openQuickAddCard(quickAddCard)

              return
            }
          }
        }
        if (this.action.card.isWindow) {
          const h = this.$createElement
          let customClass = 'custom_scrollbar '
          if (this.action.card.windowWidth) {
            customClass += `dashboard_window_width_${this.action.card.windowWidth}`
          }
          let me = this
          this.$msgbox({
            title: this.action.card.windowTitle,
            customClass: customClass,
            message: h('registry-card', { props: {
              cardId: card.id,
              registryId: this.action.card.registryId,
              readonly: readOnly,
              recordId: recordId,
              initialData: initialData
            },
            on: {
              cancelChanges: function () {
                me.$msgbox.close()
              }
            },
            key: this.generateGuid() }),
            showCancelButton: false,
            showConfirmButton: false,
            closeOnClickModal: false
          })
        } else {
          this.openRegistryCard({
            registryId: this.action.card.registryId,
            cardId: card.id,
            cardName: '',
            recordId: recordId,
            initialData: initialData,
            registry: {
              readonly: readOnly,
              addRecord: () => {},
              updateRecord: () => {}
            }
          })
        }
      }
    },
    async isQuickAddCard () {
      // Проверка на карточку быстрого добавления
      let registryData = new Registry({ id: this.action.card.registryId })
      let me = this
      let structure = await registryData.structure().first()
        .catch(() => { me.error = true })
      if (!structure) {
        return false
      }
      let quickAddCard = (structure.properties || []).find((item) => item.id === 'quick_add_card') || {}
      if (quickAddCard.value && quickAddCard.value.card_id) {
        return quickAddCard
      } else {
        return false
      }
    },
    openQuickAddCard (quickAddCard, initialData = {}) {
      const h = this.$createElement
      let customClass = 'custom_scrollbar '
      if (quickAddCard.value.width) {
        customClass += `window_width_${quickAddCard.value.width}`
      }
      let me = this
      this.$msgbox({
        customClass: customClass,
        message: h('registry-card', {
          props: {
            cardId: quickAddCard.value.card_id,
            registryId: this.action.card.registryId,
            parentContext: null,
            model: {},
            quick: true,
            initialData: initialData
          },
          on: {
            'quick-add': async function (data) {
              let cardFast = await me.getFastCard(data)
              me.openRegistryCard({
                registryId: me.action.card.registryId,
                cardId: cardFast.id,
                cardName: cardFast.name,
                recordId: null,
                initialData: data,
                registry: {
                  addRecord: (recordid) => {
                    me.getModel()[me.action.card.fieldId] = recordid
                  },
                  updateRecord: () => {}
                }
              })
              me.$msgbox.close()
            },
            cancelChanges: function () {
              me.$msgbox.close()
            }
          },
          key: this.generateGuid() }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    }
  }
}
</script>

<style>
  .dashboard_window_width_25 {
    width: 25%;
    max-height: 80%;
    overflow-y: auto;
  }
  .dashboard_window_width_50 {
    width: 50%;
    max-height: 80%;
    overflow-y: auto;
  }
  .dashboard_window_width_75 {
    width: 75%;
    max-height: 80%;
    overflow-y: auto;
  }
  .dashboard_window_width_100 {
    width: 100%;
    max-height: 80%;
    overflow-y: auto;
  }
</style>
